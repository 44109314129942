import * as React from 'react';
import IconProps from '@shared/icons/types/IconProps';

function SvgBiBriefcase({ size, color, ...props }: IconProps): React.JSX.Element {
	const finalSize = size !== undefined ? size : 16;
	const finalColor = color !== undefined ? color : 'currentColor';
	return (
		<svg
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 24 24"
			width={finalSize}
			height={finalSize}
			{...props}
		>
			<path
				d="M20 6H17V4C17 2.897 16.103 2 15 2H9C7.897 2 7 2.897 7 4V6H4C2.897 6 2 6.897 2 8V19C2 20.103 2.897 21 4 21H20C21.103 21 22 20.103 22 19V8C22 6.897 21.103 6 20 6ZM15 4V6H9V4H15ZM8 8H20V11H4V8H8ZM4 19V13H10V15H14V13H20L20.001 19H4Z"
				fill={finalColor}
			/>
		</svg>
	);
}
export default SvgBiBriefcase;
