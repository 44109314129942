import * as React from 'react';
import IconProps from '@shared/icons/types/IconProps';

function SvgIoHandRightOutline({ size, color, ...props }: IconProps): React.JSX.Element {
	const finalSize = size !== undefined ? size : 16;
	const finalColor = color !== undefined ? color : 'currentColor';
	return (
		<svg
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 24 24"
			width={finalSize}
			height={finalSize}
			{...props}
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M11.159 0.65901C11.581 0.237053 12.1533 0 12.75 0C13.3467 0 13.919 0.237053 14.341 0.65901C14.6204 0.938397 14.8187 1.2837 14.9208 1.65836C15.1823 1.55471 15.4633 1.5 15.75 1.5C16.3467 1.5 16.919 1.73705 17.341 2.15901C17.7629 2.58097 18 3.15326 18 3.75V4.62868C18.2387 4.5443 18.492 4.5 18.75 4.5C19.3467 4.5 19.919 4.73705 20.341 5.15901C20.7629 5.58097 21 6.15326 21 6.75V15C21 15.4142 20.6642 15.75 20.25 15.75C19.8358 15.75 19.5 15.4142 19.5 15V6.75C19.5 6.55109 19.421 6.36032 19.2803 6.21967C19.1397 6.07902 18.9489 6 18.75 6C18.5511 6 18.3603 6.07902 18.2197 6.21967C18.079 6.36032 18 6.55109 18 6.75V12C18 12.4142 17.6642 12.75 17.25 12.75C16.8358 12.75 16.5 12.4142 16.5 12V3.75C16.5 3.55109 16.421 3.36032 16.2803 3.21967C16.1397 3.07902 15.9489 3 15.75 3C15.5511 3 15.3603 3.07902 15.2197 3.21967C15.079 3.36032 15 3.55109 15 3.75V11.25C15 11.6642 14.6642 12 14.25 12C13.8358 12 13.5 11.6642 13.5 11.25V2.25C13.5 2.05109 13.421 1.86032 13.2803 1.71967C13.1397 1.57902 12.9489 1.5 12.75 1.5C12.5511 1.5 12.3603 1.57902 12.2197 1.71967C12.079 1.86032 12 2.05109 12 2.25V11.2969C12 11.7111 11.6642 12.0469 11.25 12.0469C10.8358 12.0469 10.5 11.7111 10.5 11.2969V4.5C10.5 4.30109 10.421 4.11032 10.2803 3.96967C10.1397 3.82902 9.94891 3.75 9.75 3.75C9.55109 3.75 9.36032 3.82902 9.21967 3.96967C9.07902 4.11032 9 4.30109 9 4.5V15C9 15.4142 8.66421 15.75 8.25 15.75C7.83579 15.75 7.5 15.4142 7.5 15V4.5C7.5 3.90326 7.73705 3.33097 8.15901 2.90901C8.58097 2.48705 9.15326 2.25 9.75 2.25C10.008 2.25 10.2613 2.2943 10.5 2.37868V2.25C10.5 1.65326 10.7371 1.08097 11.159 0.65901Z"
				fill={finalColor}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M5.86263 11.2042C5.68218 10.8964 5.2319 10.7567 4.83313 10.9833L4.83299 10.9834C4.64457 11.0904 4.55965 11.2211 4.52281 11.3704C4.48103 11.5397 4.48987 11.7904 4.60979 12.1154L4.61052 12.1174L4.61052 12.1174L7.07335 18.8508C7.51628 19.9024 8.09903 20.7913 8.99405 21.4268C9.89035 22.0632 11.1771 22.5 13.1251 22.5C15.0163 22.5 16.5831 21.8749 17.6827 20.6807C18.7877 19.4806 19.5001 17.6188 19.5001 15C19.5001 14.5858 19.8359 14.25 20.2501 14.25C20.6643 14.25 21.0001 14.5858 21.0001 15C21.0001 17.8843 20.2125 20.1476 18.7862 21.6967C17.3545 23.2517 15.3588 24 13.1251 24C10.9481 24 9.33532 23.5087 8.12564 22.6498C6.91915 21.7932 6.19074 20.6252 5.68338 19.415C5.67891 19.4043 5.67468 19.3935 5.6707 19.3827L3.20253 12.6346C3.2024 12.6343 3.20227 12.6339 3.20214 12.6336C3.01016 12.1128 2.93388 11.5485 3.0665 11.011C3.20414 10.4533 3.55347 9.98516 4.092 9.67921M5.86263 11.2042L7.55736 15.2875C7.71614 15.6701 8.15499 15.8515 8.53756 15.6927C8.92014 15.5339 9.10155 15.0951 8.94277 14.7125L7.2323 10.5913C7.22357 10.5702 7.21389 10.5496 7.20328 10.5295C6.58652 9.35761 5.13818 9.08477 4.09214 9.67913"
				fill={finalColor}
			/>
		</svg>
	);
}
export default SvgIoHandRightOutline;
