import * as React from 'react';
import IconProps from '@shared/icons/types/IconProps';

function SvgMdKeyboardArrowRight({ size, color, ...props }: IconProps): React.JSX.Element {
	const finalSize = size !== undefined ? size : 16;
	const finalColor = color !== undefined ? color : 'currentColor';
	return (
		<svg
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 24 24"
			width={finalSize}
			height={finalSize}
			{...props}
		>
			<path
				d="M8.58997 16.59L13.17 12L8.58997 7.41L9.99997 6L16 12L9.99997 18L8.58997 16.59Z"
				fill={finalColor}
			/>
		</svg>
	);
}
export default SvgMdKeyboardArrowRight;
