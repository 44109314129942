import * as React from 'react';
import IconProps from '@shared/icons/types/IconProps';

function SvgIoSpeedometerOutline({ size, color, ...props }: IconProps): React.JSX.Element {
	const finalSize = size !== undefined ? size : 16;
	const finalColor = color !== undefined ? color : 'currentColor';
	return (
		<svg
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 24 24"
			width={finalSize}
			height={finalSize}
			{...props}
		>
			<path
				d="M15.2858 10.8703L13.0593 14.4094C12.9682 14.5356 12.8574 14.6464 12.7312 14.7375C12.4264 14.9501 12.0501 15.034 11.6838 14.9708C11.3176 14.9077 10.9911 14.7027 10.7751 14.4003C10.5591 14.0979 10.4711 13.7225 10.5301 13.3557C10.5892 12.9888 10.7906 12.66 11.0905 12.4406L14.6296 10.2141C14.71 10.158 14.8058 10.1279 14.9038 10.1279C15.0019 10.1279 15.0976 10.158 15.178 10.2141C15.2791 10.287 15.3471 10.397 15.3673 10.52C15.3875 10.6429 15.3582 10.7689 15.2858 10.8703Z"
				fill={finalColor}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M0.75001 13.4948C0.750291 7.28194 5.78797 2.25 12 2.25C18.2119 2.25 23.2494 7.28166 23.25 13.4943M0.75001 13.4948C0.746326 16.2439 1.75285 18.8986 3.57827 20.9542L3.5782 20.9543L3.58621 20.963C3.58987 20.967 3.59685 20.9749 3.60619 20.9854C3.63838 21.0216 3.69861 21.0893 3.74762 21.1394C3.92717 21.331 4.14396 21.484 4.38474 21.589C4.62978 21.6958 4.89434 21.7506 5.16165 21.7499C5.42897 21.7492 5.69323 21.693 5.93769 21.5848C6.18165 21.4769 6.40058 21.3195 6.58069 21.1228C7.2704 20.3734 8.10802 19.7753 9.04064 19.3661C9.97356 18.9567 10.9812 18.7454 12 18.7454C13.0188 18.7454 14.0265 18.9567 14.9594 19.3661C15.892 19.7753 16.7296 20.3734 17.4193 21.1228C17.5994 21.3195 17.8184 21.4769 18.0623 21.5848C18.3068 21.693 18.571 21.7492 18.8384 21.7499C19.1057 21.7506 19.3702 21.6958 19.6153 21.589C19.8603 21.4821 20.0805 21.3256 20.2619 21.1292L20.2645 21.1264L20.4145 20.9623L20.4145 20.9624L20.4217 20.9542C22.2471 18.8987 23.2536 16.2443 23.25 13.4953M12 3.75C6.61535 3.75 2.25001 8.11152 2.25001 13.4953L2.25001 13.4964C2.24671 15.876 3.11721 18.1739 4.69617 19.9541C4.73387 19.9953 4.75489 20.0193 4.77034 20.0369C4.78839 20.0575 4.79884 20.0694 4.8194 20.09C4.82641 20.097 4.83328 20.1041 4.84001 20.1114C4.88048 20.1552 4.9296 20.1902 4.98427 20.214C5.03894 20.2378 5.09797 20.2501 5.15761 20.2499C5.21726 20.2497 5.27622 20.2372 5.33075 20.2131C5.38529 20.1889 5.43423 20.1537 5.47446 20.1097L5.47624 20.1078C6.30656 19.2054 7.31505 18.4852 8.43796 17.9925C9.56086 17.4998 10.7738 17.2454 12 17.2454C13.2262 17.2454 14.4392 17.4998 15.5621 17.9925C16.685 18.4852 17.6935 19.2054 18.5238 20.1078L18.5256 20.1097C18.5658 20.1537 18.6147 20.1889 18.6693 20.2131C18.7238 20.2372 18.7828 20.2497 18.8424 20.2499C18.902 20.2501 18.9611 20.2378 19.0157 20.214C19.07 20.1903 19.1188 20.1558 19.1591 20.1124C19.1594 20.1121 19.1597 20.1118 19.16 20.1114L19.3036 19.9543C20.8827 18.1742 21.7533 15.8761 21.75 13.4964L21.75 13.4953C21.75 8.11152 17.3847 3.75 12 3.75Z"
				fill={finalColor}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12 5.25C12.4142 5.25 12.75 5.58579 12.75 6V7.5C12.75 7.91421 12.4142 8.25 12 8.25C11.5858 8.25 11.25 7.91421 11.25 7.5V6C11.25 5.58579 11.5858 5.25 12 5.25ZM6.16623 7.66623C6.45913 7.37334 6.934 7.37334 7.22689 7.66623L8.28767 8.72701C8.58057 9.01991 8.58057 9.49478 8.28767 9.78767C7.99478 10.0806 7.51991 10.0806 7.22701 9.78767L6.16623 8.72689C5.87334 8.434 5.87334 7.95913 6.16623 7.66623ZM17.8338 7.66623C18.1267 7.95913 18.1267 8.434 17.8338 8.72689L16.773 9.78767C16.4801 10.0806 16.0052 10.0806 15.7123 9.78767C15.4194 9.49478 15.4194 9.01991 15.7123 8.72701L16.7731 7.66623C17.066 7.37334 17.5409 7.37334 17.8338 7.66623ZM3.75 13.5C3.75 13.0858 4.08579 12.75 4.5 12.75H6C6.41421 12.75 6.75 13.0858 6.75 13.5C6.75 13.9142 6.41421 14.25 6 14.25H4.5C4.08579 14.25 3.75 13.9142 3.75 13.5ZM17.25 13.5C17.25 13.0858 17.5858 12.75 18 12.75H19.5C19.9142 12.75 20.25 13.0858 20.25 13.5C20.25 13.9142 19.9142 14.25 19.5 14.25H18C17.5858 14.25 17.25 13.9142 17.25 13.5Z"
				fill={finalColor}
			/>
		</svg>
	);
}
export default SvgIoSpeedometerOutline;
