import * as React from 'react';
import IconProps from '@shared/icons/types/IconProps';

function SvgBiQr({ size, color, ...props }: IconProps): React.JSX.Element {
	const finalSize = size !== undefined ? size : 16;
	const finalColor = color !== undefined ? color : 'currentColor';
	return (
		<svg
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 24 24"
			width={finalSize}
			height={finalSize}
			{...props}
		>
			<path
				d="M3 11H11V3H3V11ZM5 5H9V9H5V5ZM3 21H11V13H3V21ZM5 15H9V19H5V15ZM13 3V11H21V3H13ZM19 9H15V5H19V9ZM13.01 13H15.01V15H13.01V13ZM15.01 15H17.01V17H15.01V15ZM13.01 17H15.01V19H13.01V17ZM17.01 17H19.01V19H17.01V17ZM19.01 19H21.01V21H19.01V19ZM15.01 19H17.01V21H15.01V19ZM17.01 13H19.01V15H17.01V13ZM19.01 15H21.01V17H19.01V15Z"
				fill={finalColor}
			/>
		</svg>
	);
}
export default SvgBiQr;
