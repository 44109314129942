import * as React from 'react';
import IconProps from '@shared/icons/types/IconProps';

function SvgIoRefresh({ size, color, ...props }: IconProps): React.JSX.Element {
	const finalSize = size !== undefined ? size : 16;
	const finalColor = color !== undefined ? color : 'currentColor';
	return (
		<svg
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 24 24"
			width={finalSize}
			height={finalSize}
			{...props}
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M7.41655 6.92163C8.77325 6.0151 10.3683 5.53125 12 5.53125C13.0484 5.53125 13.7844 5.56665 14.2988 5.62371C14.7743 5.67646 15.1704 5.75673 15.4416 5.92093C15.5878 6.00945 15.8211 6.19839 15.8811 6.53362C15.9413 6.86986 15.7851 7.11753 15.6986 7.22537C15.6082 7.33795 15.5079 7.40978 15.4562 7.44413C15.4259 7.46428 15.399 7.48018 15.3784 7.49171C15.368 7.49757 15.3587 7.50257 15.3509 7.50667L15.3401 7.51222L15.3355 7.51454L15.3334 7.51558L15.3324 7.51607C15.3319 7.51631 15.3314 7.51655 15 6.84375L15.3314 7.51655C14.9599 7.69959 14.5102 7.54675 14.3272 7.17518C14.3209 7.16242 14.315 7.14958 14.3095 7.13665C14.2577 7.12931 14.1992 7.12186 14.1334 7.11457C13.697 7.06616 13.0226 7.03125 12 7.03125C10.665 7.03125 9.35994 7.42713 8.2499 8.16883C7.13987 8.91053 6.27471 9.96474 5.76382 11.1981C5.25293 12.4315 5.11925 13.7887 5.3797 15.0981C5.64015 16.4075 6.28303 17.6102 7.22703 18.5542C8.17104 19.4982 9.37377 20.1411 10.6831 20.4016C11.9925 20.662 13.3497 20.5283 14.5831 20.0174C15.8165 19.5065 16.8707 18.6414 17.6124 17.5313C18.3541 16.4213 18.75 15.1163 18.75 13.7812C18.75 13.367 19.0858 13.0312 19.5 13.0312C19.9142 13.0312 20.25 13.367 20.25 13.7812C20.25 15.4129 19.7661 17.008 18.8596 18.3647C17.9531 19.7214 16.6646 20.7788 15.1571 21.4033C13.6497 22.0277 11.9909 22.1911 10.3905 21.8727C8.79017 21.5544 7.32016 20.7687 6.16637 19.6149C5.01259 18.4611 4.22685 16.9911 3.90853 15.3907C3.5902 13.7904 3.75357 12.1316 4.378 10.6241C5.00242 9.11662 6.05984 7.82815 7.41655 6.92163Z"
				fill={finalColor}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M11.4697 2.18842C11.7626 1.89553 12.2374 1.89553 12.5303 2.18842L16.2803 5.93842C16.5732 6.23131 16.5732 6.70619 16.2803 6.99908L12.5303 10.7491C12.2374 11.042 11.7626 11.042 11.4697 10.7491C11.1768 10.4562 11.1768 9.98131 11.4697 9.68842L14.6893 6.46875L11.4697 3.24908C11.1768 2.95619 11.1768 2.48131 11.4697 2.18842Z"
				fill={finalColor}
			/>
		</svg>
	);
}
export default SvgIoRefresh;
