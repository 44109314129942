import * as React from 'react';
import IconProps from '@shared/icons/types/IconProps';

function SvgBsFillMoonFill({ size, color, ...props }: IconProps): React.JSX.Element {
	const finalSize = size !== undefined ? size : 16;
	const finalColor = color !== undefined ? color : 'currentColor';
	return (
		<svg
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 24 24"
			width={finalSize}
			height={finalSize}
			{...props}
		>
			<path
				d="M9 0.416959C9.14642 0.594878 9.23612 0.812617 9.25751 1.04204C9.2789 1.27147 9.231 1.50204 9.12 1.70396C8.25279 3.29617 7.7999 5.0809 7.803 6.89396C7.803 12.9255 12.72 17.8095 18.78 17.8095C19.5705 17.8095 20.34 17.727 21.0795 17.5695C21.3062 17.5204 21.5423 17.5391 21.7584 17.6234C21.9745 17.7077 22.1609 17.8538 22.2945 18.0435C22.4355 18.2406 22.5074 18.4789 22.499 18.7211C22.4905 18.9634 22.4023 19.196 22.248 19.383C21.0715 20.8281 19.5874 21.9925 17.9038 22.7912C16.2201 23.59 14.3795 24.0029 12.516 24C5.601 24 0 18.429 0 11.565C0 6.39896 3.171 1.96796 7.686 0.0899595C7.91091 -0.00509275 8.16058 -0.0246018 8.39752 0.034363C8.63447 0.0933277 8.84588 0.227582 9 0.416959Z"
				fill={finalColor}
			/>
		</svg>
	);
}
export default SvgBsFillMoonFill;
