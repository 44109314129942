import * as React from 'react';
import IconProps from '@shared/icons/types/IconProps';

function SvgIoRadioButtonOff({ size, color, ...props }: IconProps): React.JSX.Element {
	const finalSize = size !== undefined ? size : 16;
	const finalColor = color !== undefined ? color : 'currentColor';
	return (
		<svg
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 24 24"
			width={finalSize}
			height={finalSize}
			{...props}
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M2.25 12C2.25 6.61704 6.61704 2.25 12 2.25C17.383 2.25 21.75 6.61704 21.75 12C21.75 17.383 17.383 21.75 12 21.75C6.61704 21.75 2.25 17.383 2.25 12ZM12 3.75C7.44546 3.75 3.75 7.44546 3.75 12C3.75 16.5545 7.44546 20.25 12 20.25C16.5545 20.25 20.25 16.5545 20.25 12C20.25 7.44546 16.5545 3.75 12 3.75Z"
				fill={finalColor}
			/>
		</svg>
	);
}
export default SvgIoRadioButtonOff;
