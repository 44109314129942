import * as React from 'react';
import IconProps from '@shared/icons/types/IconProps';

function SvgMdKeyboardArrowLeft({ size, color, ...props }: IconProps): React.JSX.Element {
	const finalSize = size !== undefined ? size : 16;
	const finalColor = color !== undefined ? color : 'currentColor';
	return (
		<svg
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 24 24"
			width={finalSize}
			height={finalSize}
			{...props}
		>
			<path d="M15.41 16.59L10.83 12L15.41 7.41L14 6L8 12L14 18L15.41 16.59Z" fill={finalColor} />
		</svg>
	);
}
export default SvgMdKeyboardArrowLeft;
