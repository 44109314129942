import * as React from 'react';
import IconProps from '@shared/icons/types/IconProps';

function SvgFiLink({ size, color, ...props }: IconProps): React.JSX.Element {
	const finalSize = size !== undefined ? size : 16;
	const finalColor = color !== undefined ? color : 'currentColor';
	return (
		<svg
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 24 24"
			width={finalSize}
			height={finalSize}
			{...props}
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12.7753 2.75074C13.907 1.65779 15.4226 1.05302 16.9958 1.06669C18.5689 1.08036 20.0738 1.71138 21.1863 2.82383C22.2987 3.93628 22.9297 5.44115 22.9434 7.01433C22.9571 8.58751 22.3523 10.1031 21.2593 11.2347L21.2472 11.2471L18.2473 14.247C17.639 14.8555 16.907 15.326 16.1009 15.6267C15.2948 15.9274 14.4334 16.0513 13.5753 15.9898C12.7171 15.9283 11.8822 15.683 11.1272 15.2705C10.3721 14.858 9.71464 14.288 9.19929 13.599C8.86849 13.1568 8.95883 12.5301 9.40108 12.1993C9.84333 11.8685 10.47 11.9588 10.8008 12.401C11.1444 12.8604 11.5827 13.2404 12.0861 13.5154C12.5894 13.7904 13.146 13.9539 13.7181 13.9949C14.2903 14.0359 14.8645 13.9533 15.4019 13.7529C15.9393 13.5524 16.4273 13.2387 16.8328 12.833L16.833 12.8329L19.8265 9.83941C20.5515 9.08563 20.9526 8.07776 20.9435 7.03171C20.9344 5.98292 20.5137 4.97967 19.772 4.23804C19.0304 3.49641 18.0272 3.07573 16.9784 3.06662C15.9319 3.05752 14.9236 3.45893 14.1697 4.18456L12.4551 5.88919C12.0634 6.27858 11.4303 6.27673 11.0409 5.88507C10.6515 5.49341 10.6534 4.86024 11.045 4.47086L12.765 2.76086L12.7753 2.75074Z"
				fill={finalColor}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M7.89935 8.37326C8.70546 8.07256 9.56681 7.94874 10.425 8.0102C11.2832 8.07166 12.1181 8.31697 12.8731 8.72947C13.6281 9.14198 14.2856 9.71205 14.801 10.401C15.1318 10.8433 15.0414 11.4699 14.5992 11.8007C14.1569 12.1316 13.5302 12.0412 13.1994 11.599C12.8559 11.1397 12.4175 10.7596 11.9142 10.4846C11.4108 10.2096 10.8542 10.0461 10.2821 10.0051C9.71 9.96412 9.13577 10.0467 8.59836 10.2471C8.06095 10.4476 7.57294 10.7613 7.16743 11.167L4.17379 14.1606C3.44875 14.9144 3.0477 15.9222 3.05679 16.9683C3.06591 18.0171 3.48658 19.0203 4.22821 19.762C4.96985 20.5036 5.9731 20.9243 7.02188 20.9334C8.06793 20.9425 9.0758 20.5414 9.82957 19.8164L11.5331 18.1129C11.9236 17.7223 12.5568 17.7223 12.9473 18.1129C13.3378 18.5034 13.3378 19.1366 12.9473 19.5271L11.2373 21.2371L11.2249 21.2493C10.0933 22.3422 8.57768 22.947 7.0045 22.9333C5.43132 22.9196 3.92645 22.2886 2.814 21.1762C1.70155 20.0637 1.07054 18.5589 1.05687 16.9857C1.0432 15.4125 1.64796 13.8969 2.74091 12.7653L2.75309 12.7529L5.75296 9.753C5.75292 9.75305 5.75301 9.75296 5.75296 9.753C6.3612 9.14458 7.0933 8.67395 7.89935 8.37326Z"
				fill={finalColor}
			/>
		</svg>
	);
}
export default SvgFiLink;
