import * as React from 'react';
import IconProps from '@shared/icons/types/IconProps';

function SvgTbFileImport({ size, color, ...props }: IconProps): React.JSX.Element {
	const finalSize = size !== undefined ? size : 16;
	const finalColor = color !== undefined ? color : 'currentColor';
	return (
		<svg
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 20 20"
			width={finalSize}
			height={finalSize}
			{...props}
		>
			<path
				d="M11.6667 2.5V5.83333C11.6667 6.05435 11.7545 6.26631 11.9108 6.42259C12.0671 6.57887 12.2791 6.66667 12.5001 6.66667H15.8334"
				stroke={finalColor}
				strokeWidth={1.66667}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M4.16675 10.8333V4.16667C4.16675 3.72464 4.34234 3.30072 4.6549 2.98816C4.96746 2.67559 5.39139 2.5 5.83341 2.5H11.6667L15.8334 6.66667V15.8333C15.8334 16.2754 15.6578 16.6993 15.3453 17.0118C15.0327 17.3244 14.6088 17.5 14.1667 17.5H9.58342M1.66675 15.8333H7.50008M7.50008 15.8333L5.00008 13.3333M7.50008 15.8333L5.00008 18.3333"
				stroke={finalColor}
				strokeWidth={1.66667}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}
export default SvgTbFileImport;
