import * as React from 'react';
import IconProps from '@shared/icons/types/IconProps';

function SvgBsFillFileEarmarkArrowUpFill({ size, color, ...props }: IconProps): React.JSX.Element {
	const finalSize = size !== undefined ? size : 16;
	const finalColor = color !== undefined ? color : 'currentColor';
	return (
		<svg
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 24 24"
			width={finalSize}
			height={finalSize}
			{...props}
		>
			<path
				d="M13.9395 0H6C5.20435 0 4.44129 0.316071 3.87868 0.87868C3.31607 1.44129 3 2.20435 3 3V21C3 21.7956 3.31607 22.5587 3.87868 23.1213C4.44129 23.6839 5.20435 24 6 24H18C18.7956 24 19.5587 23.6839 20.1213 23.1213C20.6839 22.5587 21 21.7956 21 21V7.0605C20.9999 6.66271 20.8418 6.28124 20.5605 6L15 0.4395C14.7188 0.158176 14.3373 8.49561e-05 13.9395 0V0ZM14.25 5.25V2.25L18.75 6.75H15.75C15.3522 6.75 14.9706 6.59196 14.6893 6.31066C14.408 6.02936 14.25 5.64782 14.25 5.25ZM9.531 14.781C9.46127 14.8507 9.37848 14.906 9.28738 14.9438C9.19627 14.9815 9.09862 15.0009 9 15.0009C8.90138 15.0009 8.80373 14.9815 8.71262 14.9438C8.62152 14.906 8.53873 14.8507 8.469 14.781C8.39927 14.7113 8.34395 14.6285 8.30621 14.5374C8.26848 14.4463 8.24905 14.3486 8.24905 14.25C8.24905 14.1514 8.26848 14.0537 8.30621 13.9626C8.34395 13.8715 8.39927 13.7887 8.469 13.719L11.469 10.719C11.5387 10.6492 11.6214 10.5937 11.7125 10.5559C11.8037 10.5181 11.9013 10.4987 12 10.4987C12.0987 10.4987 12.1963 10.5181 12.2874 10.5559C12.3786 10.5937 12.4613 10.6492 12.531 10.719L15.531 13.719C15.6007 13.7887 15.656 13.8715 15.6938 13.9626C15.7315 14.0537 15.7509 14.1514 15.7509 14.25C15.7509 14.3486 15.7315 14.4463 15.6938 14.5374C15.656 14.6285 15.6007 14.7113 15.531 14.781C15.4613 14.8507 15.3785 14.906 15.2874 14.9438C15.1963 14.9815 15.0986 15.0009 15 15.0009C14.9014 15.0009 14.8037 14.9815 14.7126 14.9438C14.6215 14.906 14.5387 14.8507 14.469 14.781L12.75 13.0605V18.75C12.75 18.9489 12.671 19.1397 12.5303 19.2803C12.3897 19.421 12.1989 19.5 12 19.5C11.8011 19.5 11.6103 19.421 11.4697 19.2803C11.329 19.1397 11.25 18.9489 11.25 18.75V13.0605L9.531 14.781Z"
				fill={finalColor}
			/>
		</svg>
	);
}
export default SvgBsFillFileEarmarkArrowUpFill;
