import * as React from 'react';
import IconProps from '@shared/icons/types/IconProps';

function SvgHiChevronRight({ size, color, ...props }: IconProps): React.JSX.Element {
	const finalSize = size !== undefined ? size : 16;
	const finalColor = color !== undefined ? color : 'currentColor';
	return (
		<svg
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 24 24"
			width={finalSize}
			height={finalSize}
			{...props}
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M8.75149 17.6484C8.52653 17.4234 8.40015 17.1182 8.40015 16.8C8.40015 16.4818 8.52653 16.1766 8.75149 15.9516L12.7031 12L8.75149 8.04839C8.5329 7.82207 8.41195 7.51895 8.41468 7.20431C8.41742 6.88968 8.54362 6.5887 8.76611 6.36621C8.9886 6.14372 9.28957 6.01752 9.60421 6.01479C9.91885 6.01205 10.222 6.13301 10.4483 6.35159L15.2483 11.1516C15.4733 11.3766 15.5996 11.6818 15.5996 12C15.5996 12.3182 15.4733 12.6234 15.2483 12.8484L10.4483 17.6484C10.2233 17.8734 9.91809 17.9997 9.59989 17.9997C9.28169 17.9997 8.97652 17.8734 8.75149 17.6484Z"
				fill={finalColor}
			/>
		</svg>
	);
}
export default SvgHiChevronRight;
