import * as React from 'react';
import IconProps from '@shared/icons/types/IconProps';

function SvgFiBarChart2({ size, color, ...props }: IconProps): React.JSX.Element {
	const finalSize = size !== undefined ? size : 16;
	const finalColor = color !== undefined ? color : 'currentColor';
	return (
		<svg
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 24 24"
			width={finalSize}
			height={finalSize}
			{...props}
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M18 9C18.5523 9 19 9.44772 19 10V20C19 20.5523 18.5523 21 18 21C17.4477 21 17 20.5523 17 20V10C17 9.44772 17.4477 9 18 9Z"
				fill={finalColor}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12 3C12.5523 3 13 3.44772 13 4V20C13 20.5523 12.5523 21 12 21C11.4477 21 11 20.5523 11 20V4C11 3.44772 11.4477 3 12 3Z"
				fill={finalColor}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M6 13C6.55228 13 7 13.4477 7 14V20C7 20.5523 6.55228 21 6 21C5.44772 21 5 20.5523 5 20V14C5 13.4477 5.44772 13 6 13Z"
				fill={finalColor}
			/>
		</svg>
	);
}
export default SvgFiBarChart2;
