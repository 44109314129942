import * as React from 'react';
import IconProps from '@shared/icons/types/IconProps';

function SvgBiImageAdd({ size, color, ...props }: IconProps): React.JSX.Element {
	const finalSize = size !== undefined ? size : 16;
	const finalColor = color !== undefined ? color : 'currentColor';
	return (
		<svg
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 24 24"
			width={finalSize}
			height={finalSize}
			{...props}
		>
			<path
				d="M4 5H17V12H19V5C19 3.897 18.103 3 17 3H4C2.897 3 2 3.897 2 5V17C2 18.103 2.897 19 4 19H12V17H4V5Z"
				fill={finalColor}
			/>
			<path d="M8 11L5 15H16L12 9L9 13L8 11Z" fill={finalColor} />
			<path d="M19 14H17V17H14V19H17V22H19V19H22V17H19V14Z" fill={finalColor} />
		</svg>
	);
}
export default SvgBiImageAdd;
