import * as React from 'react';
import IconProps from '@shared/icons/types/IconProps';

function SvgFiEdit3({ size, color, ...props }: IconProps): React.JSX.Element {
	const finalSize = size !== undefined ? size : 16;
	const finalColor = color !== undefined ? color : 'currentColor';
	return (
		<svg
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 24 24"
			width={finalSize}
			height={finalSize}
			{...props}
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M11 20C11 19.4477 11.4477 19 12 19H21C21.5523 19 22 19.4477 22 20C22 20.5523 21.5523 21 21 21H12C11.4477 21 11 20.5523 11 20Z"
				fill={finalColor}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M18 3.87866C17.7026 3.87866 17.4174 3.9968 17.2071 4.20709L4.90299 16.5112L4.37439 18.6256L6.48877 18.097L18.7929 5.79288C18.897 5.68875 18.9796 5.56514 19.036 5.42909C19.0923 5.29305 19.1213 5.14724 19.1213 4.99998C19.1213 4.85273 19.0923 4.70692 19.036 4.57087C18.9796 4.43483 18.897 4.31121 18.7929 4.20709C18.6888 4.10296 18.5652 4.02037 18.4291 3.96402C18.2931 3.90767 18.1473 3.87866 18 3.87866ZM15.7929 2.79288C16.3783 2.20751 17.1722 1.87866 18 1.87866C18.4099 1.87866 18.8158 1.9594 19.1945 2.11626C19.5732 2.27312 19.9173 2.50303 20.2071 2.79288C20.497 3.08272 20.7269 3.42681 20.8837 3.8055C21.0406 4.1842 21.1213 4.59008 21.1213 4.99998C21.1213 5.40988 21.0406 5.81576 20.8837 6.19446C20.7269 6.57316 20.497 6.91725 20.2071 7.20709L7.70713 19.7071C7.57897 19.8352 7.41839 19.9262 7.24256 19.9701L3.24256 20.9701C2.90178 21.0553 2.54129 20.9555 2.29291 20.7071C2.04453 20.4587 1.94468 20.0982 2.02988 19.7574L3.02988 15.7574C3.07384 15.5816 3.16476 15.421 3.29291 15.2929L15.7929 2.79288Z"
				fill={finalColor}
			/>
		</svg>
	);
}
export default SvgFiEdit3;
