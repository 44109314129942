import * as React from 'react';
import IconProps from '@shared/icons/types/IconProps';

function SvgLuSettings2({ size, color, ...props }: IconProps): React.JSX.Element {
	const finalSize = size !== undefined ? size : 16;
	const finalColor = color !== undefined ? color : 'currentColor';
	return (
		<svg
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 20 20"
			width={finalSize}
			height={finalSize}
			{...props}
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M5.83333 3.5C7.38653 3.5 8.69158 4.56231 9.06167 6H15.8333V7.66667H9.06167C8.69158 9.10436 7.38653 10.1667 5.83333 10.1667C3.99238 10.1667 2.5 8.67428 2.5 6.83333C2.5 4.99238 3.99238 3.5 5.83333 3.5ZM5.83333 8.5C6.75381 8.5 7.5 7.75381 7.5 6.83333C7.5 5.91286 6.75381 5.16667 5.83333 5.16667C4.91286 5.16667 4.16667 5.91286 4.16667 6.83333C4.16667 7.75381 4.91286 8.5 5.83333 8.5Z"
				fill={finalColor}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M14.1666 17.6667C12.6135 17.6667 11.3084 16.6043 10.9383 15.1667H4.16663V13.5H10.9383C11.3084 12.0623 12.6135 11 14.1666 11C16.0075 11 17.5 12.4924 17.5 14.3333C17.5 16.1742 16.0075 17.6667 14.1666 17.6667ZM14.1666 16C15.0871 16 15.8333 15.2538 15.8333 14.3333C15.8333 13.4128 15.0871 12.6667 14.1666 12.6667C13.2461 12.6667 12.5 13.4128 12.5 14.3333C12.5 15.2538 13.2461 16 14.1666 16Z"
				fill={finalColor}
			/>
		</svg>
	);
}
export default SvgLuSettings2;
