import * as React from 'react';
import IconProps from '@shared/icons/types/IconProps';

function SvgIoCar({ size, color, ...props }: IconProps): React.JSX.Element {
	const finalSize = size !== undefined ? size : 16;
	const finalColor = color !== undefined ? color : 'currentColor';
	return (
		<svg
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 24 24"
			width={finalSize}
			height={finalSize}
			{...props}
		>
			<path
				d="M20.985 10.3491C20.9743 10.2995 20.9586 10.2511 20.9381 10.2047L19.1672 6.07219C18.7589 5.11734 17.7656 4.5 16.6355 4.5H7.36454C6.23532 4.5 5.2411 5.11734 4.83329 6.07219L3.06047 10.2047C3.02018 10.2979 2.9996 10.3984 3.00001 10.5V18.75C3.00001 18.9489 3.07902 19.1397 3.21968 19.2803C3.36033 19.421 3.55109 19.5 3.75001 19.5H5.25001C5.44892 19.5 5.63968 19.421 5.78034 19.2803C5.92099 19.1397 6.00001 18.9489 6.00001 18.75V18H18V18.75C18 18.9489 18.079 19.1397 18.2197 19.2803C18.3603 19.421 18.5511 19.5 18.75 19.5H20.25C20.4489 19.5 20.6397 19.421 20.7803 19.2803C20.921 19.1397 21 18.9489 21 18.75V10.5C21.0001 10.4493 20.995 10.3987 20.985 10.3491ZM6.75001 15C6.45333 15 6.16332 14.912 5.91665 14.7472C5.66998 14.5824 5.47772 14.3481 5.36419 14.074C5.25065 13.7999 5.22095 13.4983 5.27883 13.2074C5.33671 12.9164 5.47957 12.6491 5.68935 12.4393C5.89912 12.2296 6.1664 12.0867 6.45737 12.0288C6.74834 11.9709 7.04994 12.0006 7.32403 12.1142C7.59812 12.2277 7.83239 12.42 7.99721 12.6666C8.16203 12.9133 8.25001 13.2033 8.25001 13.5C8.25001 13.8978 8.09197 14.2794 7.81067 14.5607C7.52936 14.842 7.14783 15 6.75001 15ZM17.25 15C16.9533 15 16.6633 14.912 16.4166 14.7472C16.17 14.5824 15.9777 14.3481 15.8642 14.074C15.7507 13.7999 15.721 13.4983 15.7788 13.2074C15.8367 12.9164 15.9796 12.6491 16.1893 12.4393C16.3991 12.2296 16.6664 12.0867 16.9574 12.0288C17.2483 11.9709 17.5499 12.0006 17.824 12.1142C18.0981 12.2277 18.3324 12.42 18.4972 12.6666C18.662 12.9133 18.75 13.2033 18.75 13.5C18.75 13.8978 18.592 14.2794 18.3107 14.5607C18.0294 14.842 17.6478 15 17.25 15ZM4.88719 9.75L6.21047 6.66328C6.38016 6.26672 6.84376 6 7.36454 6H16.6355C17.1558 6 17.6199 6.26672 17.7895 6.66328L19.1128 9.75H4.88719Z"
				fill={finalColor}
			/>
		</svg>
	);
}
export default SvgIoCar;
