import * as React from 'react';
import IconProps from '@shared/icons/types/IconProps';

function SvgBsTelephoneFill({ size, color, ...props }: IconProps): React.JSX.Element {
	const finalSize = size !== undefined ? size : 16;
	const finalColor = color !== undefined ? color : 'currentColor';
	return (
		<svg
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 24 24"
			width={finalSize}
			height={finalSize}
			{...props}
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M2.8275 0.76672C3.08997 0.504653 3.40513 0.301358 3.7521 0.170305C4.09907 0.0392528 4.46994 -0.0165637 4.84011 0.00655466C5.21029 0.029673 5.57133 0.131198 5.8993 0.304402C6.22727 0.477607 6.51469 0.718535 6.7425 1.01122L9.43501 4.47022C9.9285 5.10472 10.1025 5.93122 9.90751 6.71122L9.087 9.99622C9.04458 10.1664 9.04687 10.3446 9.09366 10.5136C9.14044 10.6826 9.23013 10.8366 9.354 10.9607L13.0395 14.6462C13.1638 14.7704 13.318 14.8602 13.4873 14.907C13.6566 14.9538 13.8351 14.9559 14.0055 14.9132L17.289 14.0927C17.6739 13.9965 18.0757 13.989 18.4639 14.0709C18.8522 14.1527 19.2167 14.3218 19.53 14.5652L22.989 17.2562C24.2325 18.2237 24.3465 20.0612 23.2335 21.1727L21.6825 22.7237C20.5725 23.8337 18.9135 24.3212 17.367 23.7767C13.4088 22.384 9.81491 20.118 6.852 17.1467C3.88094 14.1842 1.61492 10.5909 0.222004 6.63322C-0.320996 5.08822 0.166505 3.42772 1.2765 2.31772L2.8275 0.76672Z"
				fill={finalColor}
			/>
		</svg>
	);
}
export default SvgBsTelephoneFill;
