import * as React from 'react';
import IconProps from '@shared/icons/types/IconProps';

function SvgMdWarningAmber({ size, color, ...props }: IconProps): React.JSX.Element {
	const finalSize = size !== undefined ? size : 16;
	const finalColor = color !== undefined ? color : 'currentColor';
	return (
		<svg
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 24 24"
			width={finalSize}
			height={finalSize}
			{...props}
		>
			<path d="M12 5.99L19.53 19H4.47L12 5.99ZM12 2L1 21H23L12 2Z" fill={finalColor} />
			<path d="M13 16H11V18H13V16ZM13 10H11V15H13V10Z" fill={finalColor} />
		</svg>
	);
}
export default SvgMdWarningAmber;
