import * as React from 'react';
import IconProps from '@shared/icons/types/IconProps';

function SvgBiBuildingHouse({ size, color, ...props }: IconProps): React.JSX.Element {
	const finalSize = size !== undefined ? size : 16;
	const finalColor = color !== undefined ? color : 'currentColor';
	return (
		<svg
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 24 24"
			width={finalSize}
			height={finalSize}
			{...props}
		>
			<path
				d="M19 2H9.00003C7.89703 2 7.00003 2.897 7.00003 4V9.586L2.29303 14.293C2.15322 14.4329 2.05802 14.611 2.01945 14.805C1.98088 14.9989 2.00068 15.2 2.07635 15.3827C2.15202 15.5654 2.28016 15.7215 2.44457 15.8314C2.60898 15.9413 2.80228 16 3.00003 16V21C3.00003 21.2652 3.10539 21.5196 3.29292 21.7071C3.48046 21.8946 3.73481 22 4.00003 22H20C20.2652 22 20.5196 21.8946 20.7071 21.7071C20.8947 21.5196 21 21.2652 21 21V4C21 2.897 20.103 2 19 2ZM11 20H5.00003V14.414L8.00003 11.414L11 14.414V20ZM19 20H13V16C13.198 16.0004 13.3916 15.942 13.5563 15.8322C13.7211 15.7224 13.8495 15.5662 13.9252 15.3833C14.001 15.2004 14.0207 14.9991 13.9818 14.805C13.943 14.6109 13.8473 14.4327 13.707 14.293L9.00003 9.586V4H19V20Z"
				fill={finalColor}
			/>
			<path
				d="M11 6H13V8H11V6ZM15 6H17V8H15V6ZM15 10.031H17V12H15V10.031ZM15 14H17V16H15V14ZM7 15H9V17H7V15Z"
				fill={finalColor}
			/>
		</svg>
	);
}
export default SvgBiBuildingHouse;
