import * as React from 'react';
import IconProps from '@shared/icons/types/IconProps';

function SvgFiSun({ size, color, ...props }: IconProps): React.JSX.Element {
	const finalSize = size !== undefined ? size : 16;
	const finalColor = color !== undefined ? color : 'currentColor';
	return (
		<svg
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 24 24"
			width={finalSize}
			height={finalSize}
			{...props}
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12 8C9.79086 8 8 9.79086 8 12C8 14.2091 9.79086 16 12 16C14.2091 16 16 14.2091 16 12C16 9.79086 14.2091 8 12 8ZM6 12C6 8.68629 8.68629 6 12 6C15.3137 6 18 8.68629 18 12C18 15.3137 15.3137 18 12 18C8.68629 18 6 15.3137 6 12Z"
				fill={finalColor}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12 0C12.5523 0 13 0.447715 13 1V3C13 3.55228 12.5523 4 12 4C11.4477 4 11 3.55228 11 3V1C11 0.447715 11.4477 0 12 0Z"
				fill={finalColor}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12 20C12.5523 20 13 20.4477 13 21V23C13 23.5523 12.5523 24 12 24C11.4477 24 11 23.5523 11 23V21C11 20.4477 11.4477 20 12 20Z"
				fill={finalColor}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M3.51311 3.51286C3.90363 3.12234 4.5368 3.12234 4.92732 3.51286L6.34732 4.93286C6.73785 5.32339 6.73785 5.95655 6.34732 6.34708C5.9568 6.7376 5.32363 6.7376 4.93311 6.34708L3.51311 4.92708C3.12258 4.53655 3.12258 3.90339 3.51311 3.51286Z"
				fill={finalColor}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M17.6528 17.6529C18.0433 17.2624 18.6764 17.2624 19.067 17.6529L20.487 19.0729C20.8775 19.4634 20.8775 20.0966 20.487 20.4871C20.0964 20.8776 19.4633 20.8776 19.0728 20.4871L17.6528 19.0671C17.2622 18.6766 17.2622 18.0434 17.6528 17.6529Z"
				fill={finalColor}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M0 12C0 11.4477 0.447715 11 1 11H3C3.55228 11 4 11.4477 4 12C4 12.5523 3.55228 13 3 13H1C0.447715 13 0 12.5523 0 12Z"
				fill={finalColor}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M20 12C20 11.4477 20.4477 11 21 11H23C23.5523 11 24 11.4477 24 12C24 12.5523 23.5523 13 23 13H21C20.4477 13 20 12.5523 20 12Z"
				fill={finalColor}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M6.34732 17.6529C6.73785 18.0434 6.73785 18.6766 6.34732 19.0671L4.92732 20.4871C4.5368 20.8776 3.90363 20.8776 3.51311 20.4871C3.12258 20.0966 3.12258 19.4634 3.51311 19.0729L4.93311 17.6529C5.32363 17.2624 5.9568 17.2624 6.34732 17.6529Z"
				fill={finalColor}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M20.487 3.51286C20.8775 3.90339 20.8775 4.53655 20.487 4.92708L19.067 6.34708C18.6764 6.7376 18.0433 6.7376 17.6528 6.34708C17.2622 5.95655 17.2622 5.32339 17.6528 4.93286L19.0728 3.51286C19.4633 3.12234 20.0964 3.12234 20.487 3.51286Z"
				fill={finalColor}
			/>
		</svg>
	);
}
export default SvgFiSun;
