import * as React from 'react';
import IconProps from '@shared/icons/types/IconProps';

function SvgHiBuildingOffice({ size, color, ...props }: IconProps): React.JSX.Element {
	const finalSize = size !== undefined ? size : 16;
	const finalColor = color !== undefined ? color : 'currentColor';
	return (
		<svg
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 24 24"
			width={finalSize}
			height={finalSize}
			{...props}
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M1.20001 3.29999C1.20001 2.80294 1.60296 2.39999 2.10001 2.39999H14.7C15.1971 2.39999 15.6 2.80294 15.6 3.29999C15.6 3.79705 15.1971 4.19999 14.7 4.19999H14.4V20.7C14.4 21.1971 13.9971 21.6 13.5 21.6H11.7C11.203 21.6 10.8 21.1971 10.8 20.7V17.7C10.8 17.2029 10.3971 16.8 9.90001 16.8H6.90001C6.40296 16.8 6.00001 17.2029 6.00001 17.7V20.7C6.00001 21.1971 5.59707 21.6 5.10001 21.6H2.10001C1.60296 21.6 1.20001 21.1971 1.20001 20.7C1.20001 20.2029 1.60296 19.8 2.10001 19.8H2.40001V4.19999H2.10001C1.60296 4.19999 1.20001 3.79705 1.20001 3.29999ZM4.80001 6.59999C4.80001 6.26862 5.06864 5.99999 5.40001 5.99999H6.60001C6.93138 5.99999 7.20001 6.26862 7.20001 6.59999V7.79999C7.20001 8.13137 6.93138 8.39999 6.60001 8.39999H5.40001C5.06864 8.39999 4.80001 8.13137 4.80001 7.79999V6.59999ZM5.40001 10.8C5.06864 10.8 4.80001 11.0686 4.80001 11.4V12.6C4.80001 12.9314 5.06864 13.2 5.40001 13.2H6.60001C6.93138 13.2 7.20001 12.9314 7.20001 12.6V11.4C7.20001 11.0686 6.93138 10.8 6.60001 10.8H5.40001ZM9.60001 6.59999C9.60001 6.26862 9.86864 5.99999 10.2 5.99999H11.4C11.7314 5.99999 12 6.26862 12 6.59999V7.79999C12 8.13137 11.7314 8.39999 11.4 8.39999H10.2C9.86864 8.39999 9.60001 8.13137 9.60001 7.79999V6.59999ZM10.2 10.8C9.86864 10.8 9.60001 11.0686 9.60001 11.4V12.6C9.60001 12.9314 9.86864 13.2 10.2 13.2H11.4C11.7314 13.2 12 12.9314 12 12.6V11.4C12 11.0686 11.7314 10.8 11.4 10.8H10.2Z"
				fill={finalColor}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M17.1 7.19999C16.603 7.19999 16.2 7.60294 16.2 8.09999V20.4C16.2 21.0627 16.7373 21.6 17.4 21.6H21.9C22.3971 21.6 22.8 21.1971 22.8 20.7C22.8 20.2029 22.3971 19.8 21.9 19.8H21.6V8.99999H21.9C22.3971 8.99999 22.8 8.59705 22.8 8.09999C22.8 7.60294 22.3971 7.19999 21.9 7.19999H17.1ZM17.7 11.4C17.7 11.0686 17.9686 10.8 18.3 10.8H19.5C19.8314 10.8 20.1 11.0686 20.1 11.4V12.6C20.1 12.9314 19.8314 13.2 19.5 13.2H18.3C17.9686 13.2 17.7 12.9314 17.7 12.6V11.4ZM18.3 15.6C17.9686 15.6 17.7 15.8686 17.7 16.2V17.4C17.7 17.7314 17.9686 18 18.3 18H19.5C19.8314 18 20.1 17.7314 20.1 17.4V16.2C20.1 15.8686 19.8314 15.6 19.5 15.6H18.3Z"
				fill={finalColor}
			/>
		</svg>
	);
}
export default SvgHiBuildingOffice;
