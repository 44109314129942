import * as React from 'react';
import IconProps from '@shared/icons/types/IconProps';

function SvgIoMenu({ size, color, ...props }: IconProps): React.JSX.Element {
	const finalSize = size !== undefined ? size : 16;
	const finalColor = color !== undefined ? color : 'currentColor';
	return (
		<svg
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 24 24"
			width={finalSize}
			height={finalSize}
			{...props}
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M3 7.125C3 6.50368 3.50368 6 4.125 6H19.875C20.4963 6 21 6.50368 21 7.125C21 7.74632 20.4963 8.25 19.875 8.25H4.125C3.50368 8.25 3 7.74632 3 7.125ZM3 12C3 11.3787 3.50368 10.875 4.125 10.875H19.875C20.4963 10.875 21 11.3787 21 12C21 12.6213 20.4963 13.125 19.875 13.125H4.125C3.50368 13.125 3 12.6213 3 12ZM3 16.875C3 16.2537 3.50368 15.75 4.125 15.75H19.875C20.4963 15.75 21 16.2537 21 16.875C21 17.4963 20.4963 18 19.875 18H4.125C3.50368 18 3 17.4963 3 16.875Z"
				fill={finalColor}
			/>
		</svg>
	);
}
export default SvgIoMenu;
