import * as React from 'react';
import IconProps from '@shared/icons/types/IconProps';

function SvgFiUsers({ size, color, ...props }: IconProps): React.JSX.Element {
	const finalSize = size !== undefined ? size : 16;
	const finalColor = color !== undefined ? color : 'currentColor';
	return (
		<svg
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 24 24"
			width={finalSize}
			height={finalSize}
			{...props}
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M1.46447 15.4645C2.40215 14.5268 3.67392 14 5 14H13C14.3261 14 15.5979 14.5268 16.5355 15.4645C17.4732 16.4021 18 17.6739 18 19V21C18 21.5523 17.5523 22 17 22C16.4477 22 16 21.5523 16 21V19C16 18.2044 15.6839 17.4413 15.1213 16.8787C14.5587 16.3161 13.7956 16 13 16H5C4.20435 16 3.44129 16.3161 2.87868 16.8787C2.31607 17.4413 2 18.2044 2 19V21C2 21.5523 1.55228 22 1 22C0.447715 22 0 21.5523 0 21V19C0 17.6739 0.526784 16.4021 1.46447 15.4645Z"
				fill={finalColor}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M9 4C7.34315 4 6 5.34315 6 7C6 8.65685 7.34315 10 9 10C10.6569 10 12 8.65685 12 7C12 5.34315 10.6569 4 9 4ZM4 7C4 4.23858 6.23858 2 9 2C11.7614 2 14 4.23858 14 7C14 9.76142 11.7614 12 9 12C6.23858 12 4 9.76142 4 7Z"
				fill={finalColor}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M19.032 14.88C19.1701 14.3453 19.7155 14.0237 20.2502 14.1618C21.3229 14.4387 22.2732 15.0641 22.9519 15.9397C23.6307 16.8152 23.9994 17.8914 24.0002 18.9993L24.0002 21C24.0002 21.5523 23.5525 22 23.0002 22C22.448 22 22.0002 21.5523 22.0002 21L22.0002 19.0007C22.0002 19.0006 22.0002 19.0008 22.0002 19.0007C21.9997 18.3361 21.7784 17.6902 21.3713 17.165C20.964 16.6396 20.3938 16.2644 19.7502 16.0982C19.2155 15.9602 18.8939 15.4148 19.032 14.88Z"
				fill={finalColor}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M15.0315 2.88196C15.1685 2.34694 15.7133 2.02426 16.2483 2.16125C17.3238 2.43663 18.2771 3.06213 18.9578 3.93914C19.6386 4.81615 20.0081 5.89479 20.0081 7.005C20.0081 8.11521 19.6386 9.19385 18.9578 10.0709C18.2771 10.9479 17.3238 11.5734 16.2483 11.8488C15.7133 11.9857 15.1685 11.6631 15.0315 11.128C14.8945 10.593 15.2172 10.0482 15.7522 9.91125C16.3975 9.74603 16.9695 9.37073 17.3779 8.84452C17.7864 8.31831 18.0081 7.67113 18.0081 7.005C18.0081 6.33887 17.7864 5.69169 17.3779 5.16548C16.9695 4.63928 16.3975 4.26398 15.7522 4.09875C15.2172 3.96176 14.8945 3.41699 15.0315 2.88196Z"
				fill={finalColor}
			/>
		</svg>
	);
}
export default SvgFiUsers;
